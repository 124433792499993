<template>
  <v-row>
    <v-row justify="center" max-width="344">
      <v-col cols="10" sm="10">
        <v-form
          ref="form"
          v-model="isvalid"
          lazy-validation
        >
			<v-card>
				<v-card-title align="left" class="headline primary report-title">
					<h5>AR Aging Request</h5>
				</v-card-title>
				<br>
				<v-container>
				<v-layout wrap>
					<v-flex xs12 sm12 md2>
						<v-subheader>
							<h3>Filter By:</h3>
						</v-subheader>
					</v-flex>
					<v-flex xs12 sm12 md4>
						<v-radio-group v-model="filterBy" row dense>
							<v-radio label="Customer" value="customer"></v-radio>
							<v-radio label="Invoice Number" value="invoice_number"></v-radio>
						</v-radio-group>
					</v-flex>
					<v-flex xs6 sm3 md2 v-if="filterBy === 'customer'">
					<v-subheader>
						<h3>Payee:</h3>
					</v-subheader>
					</v-flex>
					<v-flex xs6 sm4 md3 v-if="filterBy === 'customer'">
						<v-autocomplete
							dense
							class="remove-underline"
							placeholder="Payee"
							v-model="payee_id"
							:items="payees"
							v-on:focus="getPayees"
							hide-details
							item-text="payee_desc"
							item-value="id"
							:rules="default_rules"
						></v-autocomplete>
					</v-flex>
				</v-layout>
				<v-layout wrap>
				<v-flex xs6 sm3 md2>
					<v-subheader>
						<h3>Date From:</h3>
					</v-subheader>                  
                </v-flex>
				<v-flex xs6 sm4 md3>
                  <v-menu
					v-model="from_date"
					:nudge-right="40"
					transition="scale-transition"
					offset-y
					min-width="290px"
				>
					<template v-slot:activator="{ on }">
						<v-text-field
							v-model="item.date_from"
							required
							dense
							:rules="default_rules"
							return-masked-value
							v-on="on"
							mask="YYYY-MM-DD"
							placeholder="YYYY-MM-DD">
						</v-text-field>
					</template>
					<v-date-picker
						color="primary"
						v-model="item.date_from"
					></v-date-picker>
				</v-menu>
			</v-flex>
                <v-flex md1>
                </v-flex>
                <v-flex xs6 sm3 md2>
					<v-subheader>
						<h3>Date To:</h3> 
					</v-subheader>
                </v-flex>
                <v-flex xs6 sm4 md3>
                  <v-menu
						v-model="to_date"
						:nudge-right="40"
						transition="scale-transition"
						offset-y
						min-width="290px"
					>
						<template v-slot:activator="{ on }">
							<v-text-field
								v-model="item.date_to"
								dense
								required
								:rules="default_rules"
								return-masked-value
								v-on="on"
								mask="YYYY-MM-DD"
								placeholder="YYYY-MM-DD">
							</v-text-field>
						</template>
						<v-date-picker
							color="primary"
							v-model="item.date_to"
						></v-date-picker>
					</v-menu>
				</v-flex>
              </v-layout>
				<br>
				<v-layout>
					<v-flex md1 />
					<v-btn dark color="blue" width="200" @click="generate">Generate</v-btn>
				</v-layout>
            </v-container> 
            <br>
            <br>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
	data: () => ({
		year: [],
		item: {
			date_from: '',
			date_to: ''
		},
		payee_id: 0,
		filterBy: 'customer',
		from_date: false,
		to_date: false,
		date_rules: [
			v => !!v || 'Date is required',
		],
		nextPageDepts: '',
		dataDepts: [],
		dataCoas: [],
		dataDepartments: [{id: 'ALL', dept_desc: 'All Departments'}],
		dataBranches: [{id: 'ALL', code_name: 'All Branches'}],
		nextPageCoas: '',
		isvalid: false,
		default_rules: [
			v => !!v || 'Required',
		],
	}),
	computed: {
		...mapGetters({
			periodNo: 'acctperiodmain/periodNo',
			coas: 'coa/coaSubs',
			depts: 'dept/depts',
			branches: 'branch/branches',
			coaData: 'coa/coaData',
			payees: 'payefile/payefiles',
			currUser: 'auth/currUser'
		})
	},
	mounted(){
		this.getYear();
		this.getPayees();
	},
	methods: {
		getPayees() {
			this.$store.dispatch('payefile/getPayefileDropdown'); 
		},
		getYear() {
			let data = [];
			var i;
			data.push({value: '2020', text: '2020'});
			for (i = 1; i < 5; i++) {
				let det = 2020 - i;
				det.toString();
				data.push({value: det, text: det});
			}
			this.year = data;
		},
		generate() {
			this.isvalid = this.$refs.form.validate();
			this.item.user = this.currUser.user_first_name + ' ' + this.currUser.user_last_name;

			if (this.isvalid) {
				if(this.filterBy === 'customer'){
					this.item.payee_id = this.payee_id;
					let routeData = this.$router.resolve({path: '/report-araging/'+btoa(JSON.stringify(this.item)), data: this.item});
					window.open(routeData.href, '_blank'); 
				}else{
					let routeData = this.$router.resolve({path: '/report-araging-invoice/'+btoa(JSON.stringify(this.item)), data: this.item});
					window.open(routeData.href, '_blank'); 
				}
			}
		},
		async getDataCoas(){
			if(this.coaData.length === 0){
				this.dataCoas = this.coas.data;
				this.nextPageCoas = this.coas.next_page_url;
				let page = 2;

				while (this.nextPageCoas !== null){
					await this.$store.dispatch('coa/getCoaData', page).then(response => {
						this.nextPageCoas = response.data.data.next_page_url;
						response.data.data.data.forEach(details => {
							this.dataCoas.push(details);
						});
					});
					page++;
				}
				this.$store.commit('coa/SET_COA_DATA', this.dataCoas);
			}
		},
		async getDataDepts(){
			this.dataDepts = this.depts.data;
			this.nextPageDepts = this.depts.next_page_url;
			let page = 2;

			while (this.nextPageDepts !== null){
				await this.$store.dispatch('dept/getData', page).then(response => {
					this.nextPage = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataDepts.push(details);
					});
				});
				page++;
			}
		},
	}
};
</script>
<style>
.report-title {
	color: #FFFFFF;
}
</style>